import { configureStore } from "@reduxjs/toolkit";
import productReducer from "./slices/product";
import cartReducer from "./slices/cart";
import authReducer from "./slices/auth";
import filterReducer from "./slices/filter";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, cartReducer);
const autPersistedReducer = persistReducer(persistConfig, authReducer);
const filterPersistedReducer = persistReducer(persistConfig, filterReducer);

export const store = configureStore({
  reducer: {
    product: productReducer,
    cart: persistedReducer,
    auth: autPersistedReducer,
    filter: filterPersistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);
