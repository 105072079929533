import React, { useEffect, useState } from 'react';
import {  FaFacebookF, FaRegCalendarAlt, FaRegUser } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { AiFillInstagram, AiOutlineTwitter } from 'react-icons/ai';
// import { CgMenuGridR } from 'react-icons/cg';
import DOMPurify from 'isomorphic-dompurify';


// import PostCommentForm from './PostCommentForm';
// import SearchComponent from './SearchComponent';
import RecentNewsComponent from './RecentNewsComponent';
// import ArchiveNewsComponent from './ArchiveNewsComponent';
// import TagsNewsComponent from './TagsNewsComponent';
import NewsBreadcrumb from './NewsBreadcrumb';


const SingleNews = ({postsOffline}) => {
    const [details, setDetails] = useState({});
    const location = useLocation();



    useEffect(() => {
        if (location.state && location.state.News) {
            setDetails(location.state.News);
        }
    }, [location.state]);

    const clean = DOMPurify.sanitize(details?.postContent);
    // console.log(clean);

   

    return (
        <div>
            <NewsBreadcrumb PageName={details.postTitle} />
            <div className="grid grid-cols-2 md:grid-cols-10 md:gap-7 md:mx-28 mt-10">
                <div className="flex flex-col gap-3 border-[.2rem]  border-gray-100 col-span-2 md:col-span-7 py-5 mb-20">
                    <div>
                        <img className="w-full px-11 mx-auto" src={details.postImage} alt="newsImage" />
                    </div>
                    <div className="flex flex-col gap-3 px-11 font-rajdhani">
                        <div>
                            <h3 className="font-bold">{details.postTitle}</h3>
                        </div>
                        <div className="flex flex-wrap gap-8">
                            <div className="flex flex-row gap-2">
                                <FaRegUser className="text-[#0F8C3B]" />
                                <span className="font-rajdhani text-sm font-semibold text-[#071c1f]">
                                by: Agro Market Square
                                </span>
                            </div>
                            <div className="flex flex-row gap-2">
                                <FaRegCalendarAlt className="text-[#0F8C3B]" />
                                <span className="font-rajdhani text-xs font-bold text-[#071c1f]">{details.postTime}</span>
                            </div>
                            {/* <div className="flex flex-row gap-2">
                                <FaComment className="text-[#0F8C3B]" />
                                <span className="font-rajdhani text-xs font-bold text-[#071c1f]">0 comments</span>
                            </div> */}
                        </div>
                        <div>
                            <p dangerouslySetInnerHTML={{__html:`${clean}`}} className="font-semibold font-rajdhani"></p>
                        </div>
                        <div className="w-full  h-[.02rem] bg-[#ebeaea] "></div>
                        <div className="md:flex justify-between py-1 font-rajdhani">
                            {/* <div className="my-4">
                                <h6 className="font-bold">Tags</h6>
                                <div className="flex gap-2">
                                    <button className="outline_btn h-7 mt-0">Food</button>
                                    <button className="outline_btn h-7 mt-0">Food</button>
                                </div>
                            </div> */}
                            <div className="my-4">
                                <h6 className="font-bold">Share This Post</h6>
                                <div className="flex gap-3">
                                    <FaFacebookF className="text-gray-300 hover:text-[#0F8C3B]" />
                                    <AiOutlineTwitter className="text-gray-300 hover:text-[#0F8C3B]" />
                                    <AiFillInstagram className="text-gray-300 hover:text-[#0F8C3B]" />
                                </div>
                            </div>
                        </div>
                        <div className="w-full  h-[.02rem] bg-[#ebeaea] "></div>
                        {/* <div className="font-rajdhani py-7">
                            <div className="flex justify-between ">
                                <h6 className="font-bold text-[#0F8C3B]">Prev Post</h6>
                                <h6 className="font-bold text-[#0F8C3B]">Next Post</h6>
                            </div>
                            <div className="flex justify-around">
                                <h4 className="font-bold hover:text-[#0F8C3B] cursor-pointer">
                                    {details.title && details.title.slice(0, 23)}...
                                </h4>
                                <div>
                                    <CgMenuGridR className="text-3xl text-[#0F8C3B]" />
                                </div>
                                <h4 className="font-bold hover:text-[#0F8C3B] cursor-pointer">
                                    {details.title && details.title.slice(0, 23)}...
                                </h4>
                            </div>
                        </div>
                        <div className="w-full  h-[.02rem] bg-[#ebeaea] "></div> */}
                        {/* <div className="font-rajdhani pt-7">
                            <div>
                                <h4 className="font-bold">Post Comment</h4>
                            </div>
                            <div>
                                <PostCommentForm />
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="col-span-3 ">
                    {/* <SearchComponent /> */}
                    <div className="border-[.2rem] font-rajdhani mb-4 border-gray-100 p-6">
                        <p className="font-bold">Recent News</p>
                        {
                            postsOffline.map((post) => (
                                <>
                                <RecentNewsComponent key={post._id} details={post}/>
                                <div className="w-full  h-[.02rem] bg-[#ebeaea] "></div>
                                </>
                            ))
                        }
                    </div>
                    {/* <div className="border-[.2rem] font-rajdhani mb-4 border-gray-100 p-6">
                        <div className="font-rajdhani">
                            <p className="font-bold">Archive</p>
                            <ArchiveNewsComponent details={details} />
                        </div>
                    </div> */}
                    {/* <div className="border-[.2rem] font-rajdhani mb-4 border-gray-100 p-6">
                        <TagsNewsComponent />
                    </div> */}
                    <div className="border-[.2rem] font-rajdhani mb-4 border-gray-100 p-6">
                        <div className="font-rajdhani">
                            <p className="font-bold">Never Miss News</p>
                            <div className="flex flex-wrap gap-2 pt-3">
                                <button className="green_btn h-7 mt-0">
                                    <FaFacebookF />
                                </button>
                                <button className="green_btn h-7 mt-0">
                                    <AiOutlineTwitter />
                                </button>
                                <button className="green_btn h-7 mt-0">
                                    <AiFillInstagram />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleNews;
