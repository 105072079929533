/* eslint-disable react-hooks/exhaustive-deps */
import Loader from "components/Loader";
import Nav from "components/Nav";
import { PrivateRoutes } from "components/PrivateRoutes";
import { PublicRoutes } from "components/PublicRoutes";
import ROUTES from "constants/routes";
import React, { Suspense, lazy, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import Footer from "components/Footer";
import SecondFooter from "components/SecondFooter";
import SingleProduct from "components/SingleProduct";
import SingleNews from "components/SingleNews";
import AccountDetails from "pages/Dashboard/UserDashboard/AccountDetails";
import { useSelector } from "react-redux";
import { useState } from "react";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db, auth } from "./utils/firebase";
import { signInAnonymously } from "firebase/auth";
import { ToastContainer } from "react-toastify";

const Login = lazy(() => import("pages/Auth/Login"));
const LoginPhone = lazy(() => import("pages/Auth/LoginPhone"));
const LoginPhoneVerifyOtp = lazy(() => import("pages/Auth/LoginPhoneVerifyOtp"));
const Register = lazy(() => import("pages/Auth/Register"));
const SearchPage = lazy(() => import("../src/components/SearchPage"));
const RegisterSupplier = lazy(() => import("pages/Auth/RegisterSupplier"));
const Homepage = lazy(() => import("pages/Homepage"));
const NewsPage = lazy(() => import("pages/NewsPage"));
const TrustPay = lazy(() => import("pages/TrustPay"));
const Dashboard = lazy(() => import("pages/Dashboard/UserDashboard/UserDashboard"));
const ChangePassword = lazy(() => import("pages/Dashboard/UserDashboard/ChangePassword"));
const SupplierPanel = lazy(() => import("pages/Dashboard/Supplier Dashboard/SupplierPanel"));
const SupplierAddProduct = lazy(() => import("pages/Dashboard/Supplier Dashboard/SupplierAddProduct"));
const SupplierEditProduct = lazy(() => import("pages/Dashboard/Supplier Dashboard/SupplierEditProduct"));
const SupplierOrderDetails = lazy(() => import("pages/Dashboard/Supplier Dashboard/SupplierOrderDetails"));
const SupplierProductList = lazy(() => import("pages/Dashboard/Supplier Dashboard/SupplierProductList"));
const SupplierOrderList = lazy(() => import("pages/Dashboard/Supplier Dashboard/SupplierOrderList"));
const SupplierPayments = lazy(() => import("pages/Dashboard/Supplier Dashboard/SupplierPayments"));
const SupplierSettingsBankInfo = lazy(() => import("pages/Dashboard/Supplier Dashboard/SupplierSettingsBankInfo"));
const SupplierSettingsSocialLinks = lazy(() =>
  import("pages/Dashboard/Supplier Dashboard/SupplierSettingsSocialLinks")
);
const AdminPanel = lazy(() => import("pages/Dashboard/AdminDashboard/AdminPanel"));
const AdminOrderList = lazy(() => import("pages/Dashboard/AdminDashboard/AdminOrderList"));
const AdminProductList = lazy(() => import("pages/Dashboard/AdminDashboard/AdminProductList"));
const AdminUser = lazy(() => import("pages/Dashboard/AdminDashboard/AdminUser"));
const AdminAddNews = lazy(() => import("pages/Dashboard/AdminDashboard/AdminAddNews"));
const AdminEditNews = lazy(() => import("pages/Dashboard/AdminDashboard/AdminEditNews"));
const AdminListNews = lazy(() => import("pages/Dashboard/AdminDashboard/AdminListNews"));
const About = lazy(() => import("pages/About"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));
const Location = lazy(() => import("pages/Location"));
const PrivacyAndPolicy = lazy(() => import("pages/PrivacyAndPolicy"));
const TermsAndConditions = lazy(() => import("pages/TermsAndConditions"));
const WishList = lazy(() => import("pages/WishList"));
const Order = lazy(() => import("pages/Order"));
const Contact = lazy(() => import("pages/Contact"));
const Cart = lazy(() => import("pages/Cart"));
const CategoryProduct = lazy(() => import("../src/components/CategoryProduct"));
const LocationsPage = lazy(() => import("../src/components/LocationsPage"));
const CheckOut = lazy(() => import("../src/components/CheckOut"));
const ProductRequest = lazy(() => import("../src/components/ProductRequest"));
const OurServices = lazy(() => import("../src/pages/OurServices"));

const App = () => {
  const userInfo = useSelector((state) => state.auth?.user);

  const [postsOffline, setPostsOffline] = useState([]);

  const [productsOffline, setProductsOffline] = useState([]);

  const [usersOffline, setUsersOffline] = useState([]);

  const [userData, setUserData] = useState({});

  const getUserData = async () => {
    if (userInfo?._id) {
      // Check if userInfo is defined
      const docRef = doc(db, "users", userInfo?._id); // Use userInfo._id instead of userInfo?._id
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUserData(docSnap.data());
      } else {
        // docSnap.data() will be undefined in this case
        // console.log('No such document!');
        // toast.error("Sign In!");
      }
    }
  };

  const fetchUsers = async () => {
    if (!userInfo) {
      try {
        await signInAnonymously(auth);

        const querySnapshot = await getDocs(collection(db, "users"));

        const usersData = [];
        querySnapshot.forEach((doc) => {
          usersData.push({ ...doc.data() });
        });

        setUsersOffline(usersData);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fecthUserProducts = async () => {
    if (!userInfo) {
      try {
        await signInAnonymously(auth);

        const querySnapshot = await getDocs(collection(db, "products"));

        const productsData = [];
        querySnapshot.forEach((doc) => {
          productsData.push({ ...doc.data() });
        });
        setProductsOffline(productsData);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const fecthPosts = async () => {
    if (!userInfo?._id) {
      try {
        await signInAnonymously(auth);

        if (auth.currentUser) {
          const querySnapshot = await getDocs(collection(db, "posts"));

          const postsData = [];
          querySnapshot.forEach((doc) => {
            postsData.push({ ...doc.data() });
          });

          setPostsOffline(postsData);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getUserData();
    fetchUsers();
    fecthUserProducts();
    fecthPosts();
  }, []);

  const location = useLocation();

  // useEffect(() => {
  //     if (userInfo && userInfo.role === 'vendor') {
  //         setisVendor(true)
  //     }
  // }, []);

  const showNavAndFooter = !location.pathname.startsWith(ROUTES.SUPPLIERPANEL);
  const showNavAndFooter1 = !location.pathname.startsWith(ROUTES.SUPPLIERORDERSLIST);
  const showNavAndFooter2 = !location.pathname.startsWith(ROUTES.SUPPLIEPRODUCTSLIST);
  const showNavAndFooter3 = !location.pathname.startsWith(ROUTES.SUPPLIERADDPRODUCT);
  const showNavAndFooter4 = !/^\/supplier-edit-product\/\d+$/.test(location.pathname);
  const showNavAndFooter5 = !/^\/supplier-order-details\/\d+$/.test(location.pathname);
  const showNavAndFooter6 = !location.pathname.startsWith(ROUTES.SUPPLIERPAYMENTS);
  const showNavAndFooter7 = !location.pathname.startsWith(ROUTES.SUPPLIERSETTINGSBANKINFO);
  const showNavAndFooter8 = !location.pathname.startsWith(ROUTES.SUPPLIERSETTINGSSOCIALLINKS);
  const showNavAndFooter9 = !location.pathname.startsWith(ROUTES.ADMINPANEL);
  const showNavAndFooter10 = !location.pathname.startsWith(ROUTES.ADMINORDERSLIST);
  const showNavAndFooter11 = !location.pathname.startsWith(ROUTES.ADMINPRODUCTSLIST);
  const showNavAndFooter12 = !location.pathname.startsWith(ROUTES.ADMINUSER);
  const showNavAndFooter14 = !location.pathname.startsWith(ROUTES.ADMINADDNEWS);
  const showNavAndFooter15 = !/^\/admin\/edit-news\/[\w%]+$/i.test(location.pathname);
  const showNavAndFooter16 = !location.pathname.startsWith(ROUTES.ADMINLISTNEWS);

  return (
    <Suspense
      fallback={
        <div className="h-screen flex items-center justify-center">
          <Loader size={10} />
        </div>
      }
    >
      <ChakraProvider>
        {showNavAndFooter &&
          showNavAndFooter1 &&
          showNavAndFooter2 &&
          showNavAndFooter3 &&
          showNavAndFooter4 &&
          showNavAndFooter5 &&
          showNavAndFooter6 &&
          showNavAndFooter7 &&
          showNavAndFooter8 &&
          showNavAndFooter9 &&
          showNavAndFooter10 &&
          showNavAndFooter11 &&
          showNavAndFooter12 &&
          showNavAndFooter14 &&
          showNavAndFooter15 &&
          showNavAndFooter16 && <Nav userData={userData} />}
        <Routes>
          <Route path={ROUTES.HOMEPAGE} element={<Homepage />} />
          {(userInfo?.role === "vendor" || userInfo?.role === "admin") && (
            <Route path={ROUTES.SUPPLIERPANEL} element={<SupplierPanel />} />
          )}
          {/* <Route path={ROUTES.SUPPLIERPANEL} element={<SupplierPanel />} /> */}
          <Route path={ROUTES.SUPPLIERORDERSLIST} element={<SupplierOrderList />} />
          <Route path={ROUTES.SUPPLIEPRODUCTSLIST} element={<SupplierProductList />} />
          <Route path={ROUTES.SUPPLIERADDPRODUCT} element={<SupplierAddProduct userData={userData} />} />
          <Route path={ROUTES.SUPPLIEREDITPRODUCT} element={<SupplierEditProduct />} />
          <Route path={ROUTES.SUPPLIERORDERDETAILS} element={<SupplierOrderDetails />} />
          <Route path={ROUTES.SUPPLIERPAYMENTS} element={<SupplierPayments userData={userData} />} />
          <Route path={ROUTES.SUPPLIERSETTINGSBANKINFO} element={<SupplierSettingsBankInfo />} />
          <Route path={ROUTES.SUPPLIERSETTINGSSOCIALLINKS} element={<SupplierSettingsSocialLinks />} />
          {userInfo?.role === "admin" && (
            <Route
              path={ROUTES.ADMINPANEL}
              element={<AdminPanel usersOffline={usersOffline} productsOffline={productsOffline} />}
            />
          )}
          <Route path={ROUTES.ADMINORDERSLIST} element={<AdminOrderList />} />
          <Route path={ROUTES.ADMINPRODUCTSLIST} element={<AdminProductList productsOffline={productsOffline} />} />
          <Route path={ROUTES.ADMINUSER} element={<AdminUser usersOffline={usersOffline} />} />
          <Route path={ROUTES.ADMINADDNEWS} element={<AdminAddNews />} />
          <Route path={ROUTES.ADMINEDITNEWS} element={<AdminEditNews />} />
          <Route path={ROUTES.ADMINLISTNEWS} element={<AdminListNews postsOffline={postsOffline} />} />

          <Route element={<PrivateRoutes />}>
            <Route path={ROUTES.USERDASHBOARD} element={<Dashboard />} />
            <Route path={ROUTES.CHANGEPASSWORD} element={<ChangePassword />} />
            <Route path={ROUTES.ACCOUNTDETAILS} element={<AccountDetails />} />
          </Route>

          <Route element={<PublicRoutes />}>
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.LOGINWITHPHONENUMBER} element={<LoginPhone />} />
            <Route path={ROUTES.LoginPhoneVerifyOtp} element={<LoginPhoneVerifyOtp />} />
            <Route path={ROUTES.REGISTER} element={<Register />} />
            <Route path={ROUTES.REGISTERSUPPLIER} element={<RegisterSupplier />} />
            <Route path={ROUTES.Payment} element={<TrustPay />} />
            <Route path={ROUTES.ABOUT} element={<About posts={postsOffline} />} />
            <Route path={ROUTES.PASSWORDRESET} element={<ForgotPassword />} />
            <Route path={ROUTES.LOCATION} element={<Location />} />
            <Route path={ROUTES.WISHLIST} element={<WishList />} />
            <Route path={ROUTES.CHECKOUT} element={<CheckOut />} />
            <Route path={ROUTES.PRODUCTREQUEST} element={<ProductRequest />} />
            <Route path={ROUTES.OURSERVICES} element={<OurServices />} />
            <Route path={ROUTES.PRIVACYANDPOLICY} element={<PrivacyAndPolicy />} />
            <Route path={ROUTES.TERMSANDCONDITIONS} element={<TermsAndConditions />} />
            <Route path={ROUTES.CONTACT} element={<Contact />} />
            <Route path={ROUTES.NEWSPAGE} element={<NewsPage postsOffline={postsOffline} />} />
            <Route path={ROUTES.PRODUCT} element={<SingleProduct />} />
            <Route path={ROUTES.NEWS} element={<SingleNews postsOffline={postsOffline} />} />
            <Route path={ROUTES.CART} element={<Cart />} />
            <Route path={ROUTES.SEARCH} element={<SearchPage />} />
            <Route path={ROUTES.MYORDER} element={<Order />} />
            <Route path={ROUTES.CategoryProducts} element={<CategoryProduct />} />
            <Route path={ROUTES.LOCATIONPAGE} element={<LocationsPage />} />
          </Route>

          <Route path={"*"} element={<Navigate to={ROUTES.HOMEPAGE} />} />
        </Routes>
        {showNavAndFooter &&
          showNavAndFooter1 &&
          showNavAndFooter2 &&
          showNavAndFooter3 &&
          showNavAndFooter5 &&
          showNavAndFooter6 &&
          showNavAndFooter4 &&
          showNavAndFooter7 &&
          showNavAndFooter8 &&
          showNavAndFooter9 &&
          showNavAndFooter10 &&
          showNavAndFooter11 &&
          showNavAndFooter12 &&
          showNavAndFooter14 &&
          showNavAndFooter15 &&
          showNavAndFooter16 && (
            <>
              <Footer />
              <SecondFooter />
            </>
          )}
      </ChakraProvider>
      <ToastContainer
        position="top-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHovern
        theme="dark"
      />
    </Suspense>
  );
};

export default App;
