import React from "react";

const SingleImageModal = ({ clickedImage, setClickedImage, handleRotationRight, handleRotationLeft }) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      setClickedImage(null);
    }
  };

  return (
    <div
      className="dismiss fixed top-0 left-0 right-0 h-full w-full bg-opacity-50 bg-gray-950 flex items-center"
      onClick={handleClick}
    >
      <img src={clickedImage} alt="enlarged pic" className="mx-auto max-w-[70%] max-h-[70%] m-10 shadow-md" />
      <span className="absolute top-6 right-6 text-green-900 text-3xl cursor-pointer" onClick={handleClick}>
        X
      </span>
      <div
        onClick={handleRotationLeft}
        className="flex ml-7 justify-center items-center bg-slate-50 rounded-full absolute top-1/2 w-14 h-14 left-0 z-10 cursor-pointer"
      >
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="#0F8C3B">
            <path
              fillRule="evenodd"
              d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
      <div
        onClick={handleRotationRight}
        className="flex justify-center items-center mr-7 bg-slate-50 rounded-full absolute top-1/2 right-0 w-14 h-14 z-10 cursor-pointer"
      >
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="#0F8C3B">
            <path
              fillRule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default SingleImageModal;
