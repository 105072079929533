import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    items: [],
};

export const cart = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state, action) => {
            const item = state.items.find((item) => item._id === action.payload._id);

            if (item) {
                item.quantity += action.payload.quantity;
            } else {
                state.items.push(action.payload);
            }
        },
        removeFromCart: (state, action) => {
            state.items = state.items.filter((item) => item._id !== action.payload);
        },
        emptyCart: (state, action) => {
            state.items = [];
        },
        increamentQuantity: (state, action) => {
            const item = state.items.find((item) => item._id === action.payload._id);
            if (item) {
                item.quantity++;
            }
        },
        decrementQuantity: (state, action) => {
            const item = state.items.find((item) => item._id === action.payload._id);
            if (item.quantity === 1) {
                item.quantity = 1;
            } else {
                item.quantity--;
            }
        },
    },
});

export const { addToCart, removeFromCart, emptyCart, decrementQuantity, increamentQuantity } = cart.actions;
export default cart.reducer;
