import ROUTES from "constants/routes";
import React from "react";
import { Link } from "react-router-dom";

const NewsBreadcrumb = ({ PageName }) => {
  return (
    <div>
      <div className='relative bg-[url("https://res.cloudinary.com/geoinfotech11/image/upload/v1697646099/fjl9s1oq0abaccpbxqft.jpg")] bg-no-repeat  bg-cover  h-[265px] md:h-[450px]'>
        <div className="absolute inset-0 bg-black/60 bg-opacity-100"></div>
        <div className="relative text-center md:flex justify-between items-center py-28 px-28 font-rajdhani ">
          <div className="relative z-10">
            <h6 className="text-[#0F8C3B] font-extrabold text-xl md:text-3xl">WELCOME TO OUR STORE</h6>
            <h1 className="text-left text-white font-extrabold text-xl md:text-3xl md:text-center">Article</h1>
          </div>
          <div className="flex justify-center  items-center gap-3 text-center ">
            <div className="cursor-pointer">
              <Link to="/">
                <h6 className="text-white font-extrabold text-xl md:text-3xl">Home</h6>
              </Link>
            </div>
            <div className="w-[2px] h-5 -mt-3 bg-slate-300 "></div>
            <div className="cursor-pointer">
              <Link to={ROUTES.NEWSPAGE}>
                <h6 className="text-white font-extrabold text-xl md:text-3xl">News</h6>
              </Link>
            </div>
            <div className="w-[2px] h-5 -mt-3 bg-slate-300 "></div>
          </div>
        </div>
        {/* <h6 className="text-[#0F8C3B] font-extrabold text-xl">{PageName}</h6> */}
      </div>
    </div>
  );
};

export default NewsBreadcrumb;
