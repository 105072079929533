/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { AiFillStar, AiOutlineHeart, AiOutlineStar } from "react-icons/ai";
// import { BsArrowLeftRight } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import CustomerReview from "./CustomerReview";
import { useDispatch } from "react-redux";
import { addToCart } from "redux/slices/cart";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import SingleImageModal from "./SingleImageModal";
import formatCurrency from "../utils/formatCurrency";
import { db } from "utils";
import { useParams } from "react-router-dom";
import { signInAnonymously } from "firebase/auth";
import { auth } from "../utils/firebase";

const SingleProduct = () => {
  const { id } = useParams();


  let [baseQty, setBaseQty] = useState(1);
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});

  const [imageUrls, setImageUrls] = useState([]);

  const [clickedImage, setClickedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [roundedRating, setRoundedRating] = useState(0);

  const location = useLocation();

  const getProductDetails = async () => {
    try {
      const user = await signInAnonymously(auth);

      if (user) {
        const productRef = collection(db, "products");

        const q = query(productRef, where("productname", "==", id));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          setDetails(doc.data());
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProductDetails();
    // setDetails(location.state.item);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // rating is gotten from location.state.item.reviews array, so we need pick the heighest rating from the array

  useEffect(() => {
    const storage = getStorage();
    const storageRef = ref(storage, `productimages/${details.productRandomId}/`);

    listAll(storageRef)
      .then(async (result) => {
        const urls = [];
        for (const itemRef of result.items) {
          try {
            // Get download URL for each image
            const downloadURL = await getDownloadURL(itemRef);

            urls.push(downloadURL);
          } catch (error) {
            console.error("Error getting download URL:", error);
          }
        }
        // Set the array of image URLs in the state
        setImageUrls(urls);
      })
      .catch((error) => {
        console.error("Error listing items in the folder:", error);
      });
  }, [details]);

  const productCreatorNumber = details?.productcreatornumber;

  // Check if productCreatorNumber is defined before using slice and replace
  const modifiedNumber = productCreatorNumber
    ? String(productCreatorNumber).replace(/^234/, "0")
    : details?.productcreatornumber;

  const renderStar = () => {
    const startIcons = [];

    for (let i = 0; i < 5; i++) {
      const startIcon =
        i < roundedRating ? (
          <AiFillStar key={i} className="text-yellow-500 " />
        ) : (
          <AiOutlineStar key={i} className="text-yellow-500 " />
        );

      startIcons.push(startIcon);
    }

    return startIcons;
  };

  const handleClick = (url, index) => {
    setCurrentIndex(index);
    setClickedImage(url);
  };

  const handleRotationRight = () => {
    if (currentIndex === imageUrls.length - 1) {
      setCurrentIndex(0);
      setClickedImage(imageUrls[0]);
    } else {
      setCurrentIndex(currentIndex + 1);
      setClickedImage(imageUrls[currentIndex + 1]);
    }
  };
  const handleRotationLeft = () => {
    if (currentIndex === 0) {
      setCurrentIndex(imageUrls.length - 1);
      setClickedImage(imageUrls[imageUrls.length - 1]);
    } else {
      setCurrentIndex(currentIndex - 1);
      setClickedImage(imageUrls[currentIndex - 1]);
    }
  };
  return (
    <div>
      <div className="max-w-screen-xl px-5 mx-auto my-10 md:flex gap-10">
        <div className="w-full md:w-2/5 rounded-sm relative">
          <img
            className="w-full rounded-sm h-72 md:h-[500px] object-cover"
            src={details.productmainimage}
            alt="productImg"
          />
          <div className="flex gap-2 mt-2">
            {imageUrls.map((url, index) => (
              <img
                key={index}
                className="w-20 h-20 object-cover rounded-sm border-gray-600 border cursor-pointer"
                src={url}
                alt="productImg"
                onClick={() => handleClick(url, index)}
              />
            ))}
          </div>
          {/* <div className="absolute top-4 right-0 ">
            {details.productprice > 50 ? (
              <p className="bg-[#0F8C3B] text-white font-semibold text-sm px-2  py-1 mr-2 rounded-tl-2xl rounded-br-2xl">
                Bestselling
              </p>
            ) : null}
          </div> */}
        </div>
        <div className=" w-full md:w-3/5 ">
          <div>
            <div>
              <h4 className="mt-3">{details.productname}</h4>
              <h1 className="mt-4">
                <span className="text-[#0F8C3B]">₦ </span>
                {formatCurrency(details.productprice, 2)}
              </h1>
              <p className="mt-4">{details.productdescription}</p>
            </div>
            <div className="w-full  h-[.02rem] bg-[#ebeaea] "></div>
            <div className="py-3 font-rajdhani font-semibold text-sm">
              <p>
                SKU: <span className="pl-[6.3rem]">{details.productRandomId}</span>
              </p>
              <p>
                Availability:{" "}
                <span className="pl-14">
                  <span>{details.productquantity}</span> left in stock
                </span>
              </p>
              <div className="flex justify-start items-center">
                {renderStar()}

                <span className="text-slate-400 font-normal text-xs">{`(${roundedRating}/5.0)`}</span>
              </div>
            </div>
            <div className="w-full  h-[.02rem] bg-[#ebeaea] "></div>
            <div className="mt-3">
              <h3 className="font-rajdhani text-base font-bold">Seller</h3>
              <div className="flex flex-col mt-3 font-rajdhani">
                <p className="font-medium text-[#0F8C3B] hover:underline">{details.productcreatorname}</p>
                <Link to={`tel:${modifiedNumber}`}>
                  <p className="font-medium">{modifiedNumber}</p>
                </Link>
              </div>
            </div>
            <div className="w-full  h-[.02rem] bg-[#ebeaea] "></div>
            <div className="md:flex gap-4">
              <div className="w-52 mt-3  flex justify-between  text-gray-500 gap-4 border pt-3 px-2">
                <p className="font-rajdhani font-bold text-sm ">Quantity</p>
                <div className="flex  gap-4 text-sm font-semibold  ">
                  <button
                    onClick={() => setBaseQty(baseQty === 1 ? (baseQty = 1) : baseQty - 1)}
                    className="border h-5 font-normal text-lg flex items-center justify-center px-2 hover:bg-gray-700 hover:text-white cursor-pointer duration-300 active:bg-black"
                  >
                    -
                  </button>
                  <span>{baseQty}</span>
                  <button
                    onClick={() => setBaseQty(baseQty + 1)}
                    className="border h-5 font-normal text-lg flex items-center justify-center px-2 hover:bg-gray-700 hover:text-white cursor-pointer duration-300 active:bg-black"
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="mt-3 ">
                <button
                  onClick={() =>
                    dispatch(
                      addToCart({
                        _id: details.productRandomId,
                        productId: details.userId,
                        vendoremail: details.productcreatoremail,
                        vendornumber: details.productcreatornumber,
                        _name: details.productname,
                        image: details.productmainimage,
                        price: details.productprice,
                        waybill: details.waybill,
                        availbelQuantity: details.productquantity,
                        waybilllocation: details.waybillloactions,
                        quantity: baseQty,
                      })
                    ) & toast.success(`${details.productname} is  added`)
                  }
                  className="green_btn"
                >
                  Add to cart
                </button>
              </div>
            </div>
            <div className="flex flex-wrap gap-3 mt-3 font-rajdhani">
              <div className="flex items-start gap-1 hover:text-[#0F8C3B] cursor-pointer">
                <AiOutlineHeart />
                <p className="text-xs font-bold">Add to Wishist</p>
              </div>
              {/* <div className="flex items-start gap-1 hover:text-[#0F8C3B] cursor-pointer">
                <BsArrowLeftRight />
                <p className="text-xs font-bold">Compare</p>
              </div> */}
              <div className="flex items-start gap-1 hover:text-[#0F8C3B] cursor-pointer">
                <MdEmail />
                <p className="text-xs font-bold">Ask about this product</p>
              </div>
            </div>
            <div className="w-full  h-[.02rem] bg-[#ebeaea] "></div>
            <div className="mt-3">
              <h3 className="font-rajdhani text-base font-bold">Waybill Locations</h3>
              <div className="flex gap-2">
                {details.waybillloactions?.map((waybill, index) => (
                  <h4 key={index} className="font-rajdhani text-xl font-semibold text-[#0F8C3B]">
                    {waybill.value},
                  </h4>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-screen-xl px-2 md:px-5 mx-auto my-1">
        <Tabs>
          <TabList>
            <Tab>Description</Tab>
            <Tab>Reviews</Tab>
          </TabList>
          <TabPanels>
            <TabPanel className="my-4">{details.productdescription}</TabPanel>
            <TabPanel>
              <CustomerReview details={details} renderStar={renderStar} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
      {clickedImage && (
        <SingleImageModal
          clickedImage={clickedImage}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          setImageUrls={setImageUrls}
          imageUrls={imageUrls}
          setClickedImage={setClickedImage}
          handleRotationRight={handleRotationRight}
          handleRotationLeft={handleRotationLeft}
        />
      )}
    </div>
  );
};

export default SingleProduct;
