import React from "react";
import logo from "../assets/images/logo.png";
import { CiLocationOn } from "react-icons/ci";
import { BiPhoneCall } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import { Link } from "react-router-dom";
import { HashLink as ShopLink } from "react-router-hash-link";
import { BsInstagram } from "react-icons/bs";
import { FiFacebook, FiLinkedin } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";
import { PiTelegramLogo } from "react-icons/pi";
import { XIcon } from "assets/arts";
import ROUTES from "constants/routes";

const Footer = () => {
  return (
    <footer className="bg-[#0F8C3B] text-base font-rajdhani ">
      <div className="md:flex md:justify-between md:items-center sm:px-12 px-0 py-3 -mb-9 w-full">
        <div className=" px-4 py-4 w-full md:w-[40%]">
          <div className="-mr-7 ">
            <div className="mt-5">
              <img className="w-32 text-white bg-white p-[8px] rounded-md opacity-95" src={logo} alt="logo" />
              <p className="mt-3 font-medium text-white">
                Agro Market Square: Streamlining Nigeria's Agro Supply Chain and Empowering Stakeholders.
              </p>
              <div className="flex flex-row gap-3 fo">
                <CiLocationOn className="text-lg font-semibold" />
                <p className="font-medium text-white">460 Mike Akhigbe Way, Suite C12-13, Jabi, Abuja, Nigeria</p>
              </div>
              <div className="flex flex-row gap-3">
                <BiPhoneCall className="text-lg font-semibold" />
                <Link to="tel:09154490727">
                  <p className="font-medium text-white">09154490727</p>
                </Link>
              </div>
              <div className="flex flex-row gap-3">
                <AiOutlineMail className="text-lg font-semibold" />
                <Link to="mailto:info@agromarketsquare.com">
                  <p className="font-medium text-white">info@agromarketsquare.com</p>
                </Link>
              </div>
              <div className="flex flex-row mb-2 gap-x-5">
                <BsInstagram
                  className="text-2xl cursor-pointer"
                  onClick={() =>
                    window.open("https://instagram.com/agromarketsquare?igshid=MzRlODBiNWFlZA==", "_blank")
                  }
                />

                <XIcon
                  className="h-6 w-6 cursor-pointer"
                  onClick={() => window.open("https://x.com/agromarketsquar?s=11&t=iC4JbR3qlBcNt4IrM-kKyQ", "_blank")}
                />

                <FiFacebook
                  className="text-2xl cursor-pointer"
                  onClick={() => window.open("https://m.facebook.com/agromarketsquare", "_blank")}
                />
                <FiLinkedin
                  className="text-2xl cursor-pointer"
                  onClick={() => window.open("https://www.linkedin.com/company/agro-market-square/", "_blank")}
                />
                <FaWhatsapp
                  className="text-2xl cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://wa.me/+23409159490401?text=Hello%20Agro%20Market%20Square.%20I%20have%20an%20inquiry%20about%20your%20service.",
                      "_blank"
                    )
                  }
                />
                <PiTelegramLogo
                  onClick={() => window.open("https://t.me/PriscillaAgromarketSquare", "_blank")}
                  className="text-2xl cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-[60%] grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-10 sm:px-8 px-4 py-7">
          <div className="">
            <div className="mt-10 relative overflow-hidden">
              <h6 className="font-rajdhani text-xl font-bold text-white">Company</h6>
              <div className="relative flex group overflow-hidden">
                <div className="mt-10 cursor-pointer group-hover:translate-x-8 group-hover:text-[#ffffff] transition-transform duration-500">
                  <Link to="/about">
                    <p className="font-medium text-white">About</p>
                  </Link>
                </div>
                <p className="absolute z-20 bottom-0 text-[#ffffff] hover:text-[#ffffff] flex items-center gap-1  transform -translate-x-28 group-hover:translate-x-3 transition-transform cursor-pointer duration-500">{`//`}</p>
              </div>
              <div className="relative flex group overflow-hidden">
                <div className=" cursor-pointer group-hover:translate-x-8 group-hover:text-[#ffffff] transition-transform duration-500">
                  <Link to={ROUTES.NEWSPAGE}>
                    <p className="font-medium text-white">News</p>
                  </Link>
                </div>
                <p className="absolute z-20 bottom-0 text-[#ffffff] hover:text-[#ffffff] flex items-center gap-1  transform -translate-x-28 group-hover:translate-x-3 transition-transform cursor-pointer duration-500">{`//`}</p>
              </div>
              <div className="relative flex group overflow-hidden">
                <div className="cursor-pointer group-hover:translate-x-8 group-hover:text-[#ffffff] transition-transform duration-500">
                  <Link to="/terms-and-conditions">
                    <p className="font-medium text-white ">Terms of Service</p>
                  </Link>
                </div>
                <p className="absolute z-20 bottom-0 text-[#ffffff] hover:text-[#ffffff] flex items-center gap-1  transform -translate-x-28 group-hover:translate-x-3 transition-transform cursor-pointer duration-500">{`//`}</p>
              </div>
              {/* <div className="relative flex group overflow-hidden">
                <div className="cursor-pointer group-hover:translate-x-8 group-hover:text-[#80b500] transition-transform duration-500">
                  <p>Promotional Offers</p>
                </div>
                <p className="absolute z-20 bottom-0 text-[#80b500] hover:text-[#80b500] flex items-center gap-1  transform -translate-x-28 group-hover:translate-x-3 transition-transform cursor-pointer duration-500">{`//`}</p>
              </div> */}
            </div>
          </div>
          <div className="">
            <div className="mt-10 relative overflow-hidden">
              <h6 className="font-rajdhani text-xl font-bold text-white">Services</h6>
              <div className="relative flex group overflow-hidden">
                <div className="mt-10 cursor-pointer group-hover:translate-x-8 group-hover:text-[#80b500] transition-transform duration-500">
                  <ShopLink to="/#shop" smooth>
                    <p className="font-medium text-white ">All Products</p>
                  </ShopLink>
                </div>
                <p className="absolute z-20 bottom-0 text-[#ffffff] hover:text-[#ffffff] flex items-center gap-1  transform -translate-x-28 group-hover:translate-x-3 transition-transform cursor-pointer duration-500">{`//`}</p>
              </div>
              <div className="relative flex group overflow-hidden">
                <div className="cursor-pointer group-hover:translate-x-8 group-hover:text-[#ffffff] transition-transform duration-500">
                  <Link to="/wishlist">
                    <p className="font-medium text-white ">Wishlist</p>
                  </Link>
                </div>
                <p className="absolute z-20 bottom-0 text-[#ffffff] hover:text-[#ffffff] flex items-center gap-1  transform -translate-x-28 group-hover:translate-x-3 transition-transform cursor-pointer duration-500">{`//`}</p>
              </div>
              <div className="relative flex group overflow-hidden">
                <div className=" cursor-pointer group-hover:translate-x-8 group-hover:text-[#ffffff] transition-transform duration-500">
                  <Link to="/login">
                    <p className="font-medium text-white">Login</p>
                  </Link>
                </div>
                <p className="absolute z-20 bottom-0 text-[#ffffff] hover:text-[#ffffff] flex items-center gap-1  transform -translate-x-28 group-hover:translate-x-3 transition-transform cursor-pointer duration-500">{`//`}</p>
              </div>
              {/* <div className="relative flex group overflow-hidden">
                <div className="cursor-pointer group-hover:translate-x-8 group-hover:text-[#80b500] transition-transform duration-500">
                  <p>My Account</p>
                </div>
                <p className="absolute z-20 bottom-0 text-[#80b500] hover:text-[#80b500] flex items-center gap-1  transform -translate-x-28 group-hover:translate-x-3 transition-transform cursor-pointer duration-500">{`//`}</p>
              </div> */}
            </div>
          </div>
          <div className="">
            <div className="mt-10 ">
              <h6 className="font-rajdhani text-xl font-bold text-white">Customer Care</h6>
              <div className="relative flex group overflow-hidden">
                <div className="mt-10 cursor-pointer group-hover:translate-x-8 group-hover:text-[#ffffff] transition-transform duration-500">
                  <Link to="/contact">
                    <p className="font-medium text-white">Contact</p>
                  </Link>
                </div>
                <p className="absolute z-20 bottom-0 text-[#ffffff] hover:text-[#ffffff] flex items-center gap-1  transform -translate-x-28 group-hover:translate-x-3 transition-transform cursor-pointer duration-500 ">{`//`}</p>
              </div>
              <div className="relative flex group overflow-hidden">
                <div className=" cursor-pointer group-hover:translate-x-8 group-hover:text-[#ffffff] transition-transform duration-500">
                  <Link to="/login">
                    <p className="font-medium text-white">Login</p>
                  </Link>
                </div>
                <p className="absolute z-20 bottom-0 text-[#ffffff] hover:text-[#ffffff] flex items-center gap-1  transform -translate-x-28 group-hover:translate-x-3 transition-transform cursor-pointer duration-500">{`//`}</p>
              </div>
              <div className="relative flex group overflow-hidden">
                <div className="cursor-pointer group-hover:translate-x-8 group-hover:text-[#ffffff] transition-transform duration-500">
                  <Link to="/dashboard">
                    <p className="font-medium text-white">My Account</p>
                  </Link>
                </div>
                <p className="absolute z-20 bottom-0 text-[#ffffff] hover:text-[#ffffff] flex items-center gap-1  transform -translate-x-28 group-hover:translate-x-3 transition-transform cursor-pointer duration-500">{`//`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
