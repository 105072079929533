import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "firebase/auth";
import { removeUser } from "../redux/slices/auth";
import { auth } from "../utils/firebase";
import { GoSignOut } from "react-icons/go";
import { AiOutlineSearch } from "react-icons/ai";
import { BsFillCartCheckFill } from "react-icons/bs";
import { BiSolidDashboard } from "react-icons/bi";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../utils/firebase";
import ROUTES from "constants/routes";

const Nav = ({ userData }) => {
  const productData = useSelector((state) => state.cart.items);
  const userInfo = useSelector((state) => state.auth.user);

  const [isSearchPopupVisible, setSearchPopupVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [user, setUser] = useState({});

  useEffect(() => {
    const getUserData = async () => {
      try {
        const docRef = doc(db, "users", userInfo?._id); // Use userInfo._id instead of userInfo?._id
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUser(docSnap.data());
        } else {
          // docSnap.data() will be undefined in this case
          // console.log('No such document!');
          // toast.error("Sign In!");
        }
      } catch (error) {
        // console.log("Error getting document:", error);
      }
    };

    getUserData();
  }, [userInfo?._id]);

  // const handleSearchPopUP = () => {
  //   setSearchPopupVisible(!isSearchPopupVisible);
  // };

  // const handleInputClick = (e) => {
  //   // Prevent the click on the input from closing the popup
  //   e.stopPropagation();
  // };

  // get users data from firestore

  useEffect(() => {
    function handleClickOutside(event) {
      const searchPopup = document.getElementById("search");
      if (searchPopup && !searchPopup.contains(event.target)) {
        setSearchPopupVisible(false);
      }
    }

    if (isSearchPopupVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchPopupVisible]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };
  const closeMobileNav = () => {
    setMobileNavOpen(false);
  };

  const handleAccount = () => {
    document.getElementById("account").classList.toggle("hidden");
  };

  const handleAccountLeave = () => {
    document.getElementById("account").classList.add("hidden");
  };

  const handleLogout = (e) => {
    e.preventDefault();
    signOut(auth)
      .then(() => {
        dispatch(removeUser());

        setSearchQuery("");

        setTimeout(() => {
          navigate("/");
        }, 1000);

        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <nav className="w-full h-20 bg-white border-b-[1px] border-b-gray-100 px-4 md:px-16 sticky top-0 z-50">
      <div className="max-w-screen-xl h-full mx-auto flex items-center justify-between">
        <div>
          <Link to="/">
            <img className="w-16 md:w-28" src={logo} alt="logo" />
          </Link>
        </div>
        <div className="flex items-center gap-4 mr-10">
          <div>
            <div className="flex justify-center items-center">
              <form className="flex">
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-32 md:w-40 h-8 border border-gray-200 rounded-tl-md rounded-bl-md px-3 outline-none"
                />
                <button
                  className="bg-[#0F8C3B] flex h-8 w-7 justify-center items-center cursor-pointer rounded-tr-md rounded-br-md"
                  onClick={() => {
                    if (searchQuery) {
                      navigate(`/search/${searchQuery}`);
                    }
                  }}
                >
                  <AiOutlineSearch className="text-white font-extrabold" />
                </button>
              </form>
            </div>
          </div>
          <ul className="hidden md:flex items-center gap-8">
            <Link to="/">
              <li className="nav_element">Home</li>
            </Link>
            <Link to="/about">
              <li className="nav_element">About</li>
            </Link>
            <Link to="/our-services">
              <li className="nav_element">Services</li>
            </Link>
            {userInfo && (
              <Link to="/dashboard">
                <li className="nav_element">Order</li>
              </Link>
            )}
            <Link to={ROUTES.NEWSPAGE}>
              <li className="nav_element">News</li>
            </Link>
            <Link to="/contact">
              <li className="nav_element">Contact Us</li>
            </Link>
            <Link to="/cart">
              <li className="nav_element relative mr-2">
                Cart
                <span className="absolute w-5 top-[-0.42rem]  bg-green-500 rounded-full left-[1.99rem] text-sm flex items-center justify-center font-medium">
                  {productData.length}
                </span>
              </li>
            </Link>
            {userInfo ? (
              <></>
            ) : (
              <Link to="/login">
                <li className="nav_element">Login</li>
              </Link>
            )}
          </ul>
          <Link to="/cart">
            <div className="md:hidden flex justify-center items-center -ml-3 relative">
              <BsFillCartCheckFill className="text-[#0F8C3B] w-7 h-7 " />
              <span className="absolute w-5 top-[-0.25rem]  bg-green-500 rounded-full left-[1.4rem] text-sm text-white flex items-center justify-center font-medium">
                {productData.length}
              </span>
            </div>
          </Link>
          {userInfo && (
            <div onMouseEnter={handleAccount} onMouseLeave={handleAccountLeave} className="relative">
              <div className="flex gap-3">
                <img
                  className="hidden md:flex w-8 h-8 rounded-full"
                  src={
                    userInfo
                      ? userInfo.image
                        ? userInfo.image
                        : `https://cdn-icons-png.flaticon.com/512/666/666201.png`
                      : `https://cdn-icons-png.flaticon.com/512/666/666201.png`
                  }
                  alt="userLogo"
                />
                {userInfo && (
                  <p className="text-base font-rajdhani font-semibold underline underline-offset-2">{userInfo._name}</p>
                )}
              </div>
              <div id="account" className="absolute right-2 hidden w-36 bg-slate-100 py-4 px-4 rounded">
                {userInfo ? (
                  <div>
                    <div className=" inline-flex cursor-pointer gap-1">
                      <GoSignOut className="text-[#0F8C3B]" onClick={handleLogout} />
                      <h6
                        onClick={handleLogout}
                        className="relative md:block text-[#0F8C3B]  rounded-sm font-rajdhani font-semibold hover:underline underline-offset-2 decoration-1"
                      >
                        Logout
                      </h6>
                    </div>
                    {user.userrole === "vendor" && (
                      <div>
                        <Link to="/supplier-panel">
                          <div className=" inline-flex cursor-pointer gap-1">
                            <BiSolidDashboard className="text-[#0F8C3B]" />
                            <h6 className="relative md:block text-[#0F8C3B]  rounded-sm font-rajdhani font-semibold hover:underline underline-offset-2 decoration-1">
                              Dashboard
                            </h6>
                          </div>
                        </Link>
                      </div>
                    )}
                    {user.userrole === "admin" && (
                      <div>
                        <Link to="/supplier-panel">
                          <div className=" inline-flex cursor-pointer gap-1">
                            <BiSolidDashboard className="text-[#0F8C3B]" />
                            <h6 className="relative md:block text-[#0F8C3B]  rounded-sm font-rajdhani font-semibold hover:underline underline-offset-2 decoration-1">
                              Vendor Dashboard
                            </h6>
                          </div>
                        </Link>
                        <Link to="/admin-panel">
                          <div className=" inline-flex cursor-pointer gap-1">
                            <BiSolidDashboard className="text-[#0F8C3B]" />
                            <h6 className="relative md:block text-[#0F8C3B]  rounded-sm font-rajdhani font-semibold hover:underline underline-offset-2 decoration-1">
                              Admin Dashboard
                            </h6>
                          </div>
                        </Link>
                      </div>
                    )}
                    <div className=" inline-flex cursor-pointer gap-1">
                      {/* <GoSignOut className='text-[#0F8C3B]' onClick={handleLogout} /> */}
                      <h6
                        onClick={() => {
                          navigate("/wishlist");
                        }}
                        className="relative md:block text-[#0F8C3B]  rounded-sm font-rajdhani font-semibold hover:underline underline-offset-2 decoration-1"
                      >
                        WishList
                      </h6>
                    </div>
                  </div>
                ) : (
                  <Link to="/login">
                    <h6 className="relative md:block text-[#0F8C3B]  rounded-sm font-rajdhani font-semibold hover:underline underline-offset-2 decoration-1">
                      Login
                    </h6>
                  </Link>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="md:hidden flex items-center">
          <button
            className="flex items-center  py-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
            onClick={toggleMobileNav}
          >
            <svg
              className="h-6 w-6"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </div>
      {/* Mobile Nav */}
      <div
        className={`fixed inset-y-0 left-0 z-50 w-64 bg-white overflow-y-auto transition-transform duration-500 ease-in-out transform ${
          isMobileNavOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <ul className="flex flex-col gap-4 p-4">
          <Link to="/">
            <img className="w-16 md:w-28" src={logo} alt="logo" />
          </Link>
          <Link to="/" onClick={closeMobileNav}>
            <li className="nav_element">Home</li>
          </Link>
          <Link to="/about" onClick={closeMobileNav}>
            <li className="nav_element">About</li>
          </Link>
          <Link to="/our-services" onClick={closeMobileNav}>
            <li className="nav_element">Services</li>
          </Link>
          {user.userrole === "vendor" && (
            <Link to="/supplier-panel" onClick={closeMobileNav}>
              <li className="nav_element">Dashboard</li>
            </Link>
          )}
          {user.userrole === "admin" && (
            <div>
              <Link to="/admin-panel" onClick={closeMobileNav}>
                <li className="nav_element">Admin Dashboard</li>
              </Link>
              <Link to="/supplier-panel" onClick={closeMobileNav}>
                <li className="nav_element">Vendor Dashboard</li>
              </Link>
            </div>
          )}
          {user.userrole === "customer" && (
            <Link to="/dashboard" onClick={closeMobileNav}>
              <li className="nav_element">Dashboard</li>
            </Link>
          )}
          <Link to="/contact" onClick={closeMobileNav}>
            <li className="nav_element">Contact</li>
          </Link>
          <Link to={ROUTES.NEWSPAGE} onClick={closeMobileNav}>
            <li className="nav_element">News</li>
          </Link>
          <Link to="/cart" onClick={closeMobileNav}>
            <li className="nav_element">Cart</li>
          </Link>
          {userInfo ? (
            <>
            </>
          ) : (
            <Link to="/login"  onClick={closeMobileNav}>
              <li className="nav_element">Login</li>
            </Link>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
