const ROUTES = Object.freeze({
  LOGIN: "/login",
  REGISTER: "/register",
  REGISTERSUPPLIER: "/register-after-login",
  RECOVER: "/recover-password",

  HOMEPAGE: "/",

  // Payment: "/atarapay",
  PRODUCTREQUEST: "/product-request",
  LOGINWITHPHONENUMBER: "/signin",
  LoginPhoneVerifyOtp: "/verify-otp",
  OURSERVICES: "/our-services",
  PASSWORDRESET: "/password-reset",
  ABOUT: "/about",
  LOCATION: "/locations",
  PRIVACYANDPOLICY: "/privacy-and-policy",
  TERMSANDCONDITIONS: "/terms-and-conditions",
  WISHLIST: "/wishlist",
  CONTACT: "/contact",
  PRODUCT: "/product/:randId/:id",
  CHECKOUT: "/checkout/summary",
  NEWS: "news-feed/:id",
  SEARCH: "/search/:query",
  NEWSPAGE: "news-feed",
  CART: "/cart",
  MYORDER: "/:id/order",
  USERDASHBOARD: "/dashboard",
  CHANGEPASSWORD: "/change-password",
  SUPPLIERPANEL: "/supplier-panel",
  SUPPLIERORDERSLIST: "/supplier-orders-list",
  SUPPLIEPRODUCTSLIST: "/supplier-products-list",
  SUPPLIERADDPRODUCT: "/supplier-add-product",
  SUPPLIERPAYMENTS: "/payments",
  SUPPLIERSETTINGSBANKINFO: "/settings/bank-info",
  SUPPLIERSETTINGSSOCIALLINKS: "/settings/social-links",
  ACCOUNTDETAILS: "/account_details",
  CategoryProducts: "/category/:categoryName",
  LOCATIONPAGE: "/location-store/:locationsName",
  SUPPLIEREDITPRODUCT: "/supplier-edit-product/:id",
  SUPPLIERORDERDETAILS: "/supplier-order-details/:id",
  ADMINPANEL: "/admin-panel",
  ADMINORDERSLIST: "/admin-orders-list",
  ADMINPRODUCTSLIST: "/admin-products-list",
  ADMINUSER: "/admin/users",
  ADMINADDNEWS: "/admin/add-news",
  ADMINEDITNEWS: "/admin/edit-news/:id",
  ADMINLISTNEWS: "/admin/list-news",
});

export default ROUTES;
