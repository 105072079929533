//import { useSelector } from 'react-redux';
import {  Outlet } from 'react-router-dom';

export const PublicRoutes = () => {
    // const { user } = useSelector((state) => state.auth);
    // // if (user) {
    // //     return <Navigate to={'/dashboard'} replace />;
    // // }
    return <Outlet />;
};
