// filterSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  priceFrom: "",
  priceTo: "",
  category: "",
  subcategory: "",
  location: "",
  sublocation: "",
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setPriceFromFilter: (state, action) => {
      state.priceFrom = action.payload;
    },
    setPriceToFilter: (state, action) => {
      state.priceTo = action.payload;
    },
    setCategoryFilter: (state, action) => {
      state.category = action.payload;
    },
    setSubcategoryFilter: (state, action) => {
      state.subcategory = action.payload;
    },
    setLocationFilter: (state, action) => {
      state.location = action.payload;
    },
    setSublocationFilter: (state, action) => {
      state.sublocation = action.payload;
    },

    clearFilter: (state, action) => {
      state.category = "";
      state.priceFrom = "";
      state.priceTo = "";
      state.category = "";
      state.subcategory = "";
      state.location = "";
      state.sublocation = "";
    },
  },
});

export const {
  setPriceFromFilter,
  setPriceToFilter,
  setCategoryFilter,
  setSubcategoryFilter,
  setLocationFilter,
  setSublocationFilter,
  clearFilter,
} = filterSlice.actions;

export default filterSlice.reducer;
