import React, { useState } from "react";

import ReviweForm from "./ReviweForm";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

const CustomerReview = ({ details }) => {
  const [isReviewFormVisible, setReviewFormVisible] = useState(false);

  const renderStar = (rating) => {
    const startIcons = [];

    for (let i = 0; i < 5; i++) {
      const startIcon =
        i < rating ? (
          <AiFillStar key={i} className="text-yellow-500 " />
        ) : (
          <AiOutlineStar key={i} className="text-yellow-500 " />
        );

      startIcons.push(startIcon);
    }

    return startIcons;
  };

  const handleReviewModal = () => {
    setReviewFormVisible(!isReviewFormVisible); // Toggle the value
  };

  return (
    <div>
      <div className="sm:flex justify-between">
        <div className="font-rajdhani text-base font-black w-full">
          <h4>Customer Reviews</h4>
          {details?.reviews?.map((review) => (
            <>
              <div>
                <div>
                  <span>{review.reviewerName}</span>
                </div>
                <div className="flex"> 
                  <span className="flex">{renderStar( review.rating)}</span>
                </div>
                <div>
                  <span>{review.reviewBody}</span>
                </div>
                <hr className="w-full" />
              </div>
            </>
          ))}
        </div>
        <div onClick={handleReviewModal} className="cursor-pointer underline">
          Write a Review
        </div>
      </div>
      {isReviewFormVisible && <ReviweForm details={details} />}
    </div>
  );
};

export default CustomerReview;
