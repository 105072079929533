/**
 *Should ONLY be used to format
 ** Currency
 ** Integers
 *  @returns Either one of the four formatted string use cases below
 ** 3000    -> 3,000
 ** 3000.87 -> 3,000.87
 ** 3000.6  -> 3,000.6
 ** 3000    -> 3,000.00
 */
const formatCurrency = (_amount, addDecimal) => {
  const amount = `${_amount}`;
  const decimalIdx = amount.indexOf(".");

  if (decimalIdx !== -1) {
    const wholeNumber = amount.slice(0, decimalIdx);
    let decimalNumber = amount.slice(decimalIdx);

    if (decimalNumber.length > 3) {
      decimalNumber = `${Number(amount).toFixed(2)}`.slice(decimalIdx);
    }

    return wholeNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + decimalNumber;
  } else {
    const formattedAmount = amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return addDecimal ? formattedAmount + ".00" : formattedAmount;
  }
};

export default formatCurrency;
