import React, { useState } from "react";
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../utils/firebase";
import Loader from "./Loader";

const ReviweForm = ({ details }) => {
  const [rating, setRating] = useState(0); // Initial rating state
  const [reviewerName, setReviewerName] = useState(""); // Initial reviewerName state
  const [reviewBody, setReviewBody] = useState(""); // Initial reviewBody state
  const [loading, setLoading] = useState(false); // Initial loading state

  // Function to update the rating state
  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const q = query(collection(db, "products"), where("productRandomId", "==", details.productRandomId));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (product) => {
        const docRef = doc(db, "products", product.id);
        await updateDoc(docRef, {
          reviews: [
            ...product.data()?.reviews || [],
            {
              rating,
              reviewerName,
              reviewBody,
            },
          ],
        });
      });
      setRating(0);
      setReviewerName("");
      setReviewBody("");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="md:p-10">
        <form className="block my-10 shadow-md border rounded-md p-5 md:p-10 mx-auto max-w-5xl font-rajdhani font-medium text-sm">
          <div className="">
            <h6 className="font-extrabold">Write a review</h6>
          </div>
          <div className="w-full">
            <div className="w-full">
              <div className=" w-full my-2">
                <label>Name</label>
                <input
                  className="w-full my-2 h-12 p-4 border border-gray-300 focus:outline-none focus:ring-1 focus:ring-[#80b500]"
                  type="text"
                  placeholder="Enter your name"
                  onChange={(e) => {
                    setReviewerName(e.target.value);
                  }}
                ></input>
              </div>
              <div className="w-full my-2">
                <label>Rating</label>
                <div className="w-full my-2">
                  {/* Map through an array of 5 stars and create a star rating input */}
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      className={`cursor-pointer text-3xl ${star <= rating ? "text-yellow-500" : "text-gray-300"}`}
                      onClick={() => handleRatingChange(star)}
                    >
                      ★
                    </span>
                  ))}
                </div>
              </div>
              <div className="w-full my-2">
                <label>
                  Body of Review <span>{`(${1500})`}</span>
                </label>
                <textarea
                  cols={1}
                  rows={5}
                  placeholder="Enter message"
                  className="w-full my-2 mx-auto px-4 py-4 border border-gray-300 focus:outline-none focus:ring-1 focus:ring-[#80b500]"
                  onChange={(e) => {
                    setReviewBody(e.target.value);
                  }}
                />
              </div>
              <div className="flex gap-4">
                <button className="green_btn flex-shrink-0" onClick={handleSubmit}>
                  {loading ? (<Loader />): "Submit Review"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReviweForm;
