import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../utils/firebase";
import { signInAnonymously } from "firebase/auth";
import { auth } from "../../utils/firebase";

export const fetchProducts = createAsyncThunk("fetchProducts", async () => {
  try {
    await signInAnonymously(auth);

    // You are signed in at this point
    const q = collection(db, "products");
    const querySnapshot = await getDocs(q);
    const products = [];
    querySnapshot.forEach((doc) => {
      products.push({ ...doc.data() });
    });
    return products;
  } catch (error) {
    console.log(error);
  }
});

export const productSlice = createSlice({
  name: "product",
  initialState: {
    loading: false,
    products: [],
    error: "",
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.loading = false;
      state.products = action.payload;
      state.error = "";
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.loading = false;
      state.products = [];
      state.error = action.error.message;
    });
  },
});

// export const { fecthProducts } = app.actions;
export default productSlice.reducer;
