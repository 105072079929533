const dev = {
  FB: {
    apiKey: "AIzaSyDUUc13vW4mMsAVTTU3snAi460Rmdm859g",
    authDomain: "agro-market-square.firebaseapp.com",
    projectId: "agro-market-square",
    storageBucket: "agro-market-square.appspot.com",
    messagingSenderId: "519325668920",
    appId: "1:519325668920:web:9279d82b8cbe0c838078cd",
    measurementId: "G-95LH0S63VS",
    // apiKey: "AIzaSyDRsDva5lj0PUxWkweg1IHuDFwn9aS_Byh4",
    // authDomain: "agro-ecommerce-82e77.firebaseapp.com",
    // projectId: "agro-ecommerce-82e77",
    // storageBucket: "agro-ecommerce-82e77.appspot.com",
    // messagingSenderId: "290713223023",
    // appId: "1:290713223023:web:7829d8e5d3d0442e15f29a",
    // measurementId: "G-B29QZFQXRY",
  },
  API_URL: process.env.REACT_APP_LOCATION_URL_DEV,
  fundallcreateaccountapiurl: `${process.env.REACT_APP_FUNDALL_BASE_URL_DEV}/v1/escrow/create-account`,
  fundallintiateescrowapiurl: `${process.env.REACT_APP_FUNDALL_BASE_URL_DEV}/v1/escrow/initiate`,
  fundallconfirmpayment: `${process.env.REACT_APP_FUNDALL_BASE_URL_DEV}/v1/escrow/confirm-payment`,
  fundallconfirmdelivery: `${process.env.REACT_APP_FUNDALL_BASE_URL_DEV}/v1/escrow/confirm-delivery`,
  fundallgetallbanks: `${process.env.REACT_APP_FUNDALL_BASE_URL_DEV}/v1/transfers/all-banks`,
  fullterwavegetallbanks:`${process.env.REACT_APP_FLUTTERWAVE_GET_ALL_BANKS}`,
  fundalliniatetransfer: `${process.env.REACT_APP_FUNDALL_BASE_URL_DEV}/v1/transfers/initiate-transfer`,
  fundallconfirmtransfer: `${process.env.REACT_APP_FUNDALL_BASE_URL_DEV}/v1/transfers/confirm-transfer`,
  fundallpkkey: `${process.env.REACT_APP_FUNDALL_PUBLIC_KEY_DEV}`,
  fundallskkey: `${process.env.REACT_APP_FUNDALL_SECRET_KEY_DEV}`,
  mailchimpurl: `${process.env.REACT_APP_MAILCHIMP_URL_DEV}`,
};

const prod = {
  FB: {
    apiKey: "AIzaSyAXPgwvl-J43v4MJwoZ4cB1Kp7UWfy7qFs",
    authDomain: "agro-market-square-92d91.firebaseapp.com",
    projectId: "agro-market-square-92d91",
    storageBucket: "agro-market-square-92d91.appspot.com",
    messagingSenderId: "785900991397",
    appId: "1:785900991397:web:b7d5da55a3e64b62cf2e4c",
    measurementId: "G-D2KG26V3ED",
  },
  API_URL: process.env.REACT_APP_LOCATION_URL_LIVE,
  fundallcreateaccountapiurl: `${process.env.REACT_APP_FUNDALL_BASE_URL_PROD}/v1/wallet/create`,
  fundallintiateescrowapiurl: `${process.env.REACT_APP_FUNDALL_BASE_URL_PROD}/v1/escrow/initiate`,
  fundallconfirmpayment: `${process.env.REACT_APP_FUNDALL_BASE_URL_PROD}/v1/escrow/confirm-payment`,
  fundallconfirmdelivery: `${process.env.REACT_APP_FUNDALL_BASE_URL_PROD}/v1/escrow/confirm-delivery`,
  fundallgetallbanks: `${process.env.REACT_APP_FUNDALL_BASE_URL_PROD}/v1/transfers/all-banks`,
  fullterwavegetallbanks:`${process.env.REACT_APP_FLUTTERWAVE_GET_ALL_BANKS}`,
  fundalliniatetransfer: `${process.env.REACT_APP_FUNDALL_BASE_URL_PROD}/v1/transfers/initiate-transfer`,
  fundallconfirmtransfer: `${process.env.REACT_APP_FUNDALL_BASE_URL_PROD}/v1/transfers/confirm-transfer`,
  fundallpkkey: `${process.env.REACT_APP_FUNDALL_PUBLIC_KEY_LIVE}`,
  fundallskkey: `${process.env.REACT_APP_FUNDALL_SECRET_KEY_LIVE}`,
  mailchimpurl: `${process.env.REACT_APP_MAILCHIMP_URL_LIVE}`,
};

const config = process.env.REACT_APP_STAGE === "production" ? prod : dev;

export default config;
