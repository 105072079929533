import React, { useState } from "react";
import { BiUser } from "react-icons/bi";
import { BsBagDash } from "react-icons/bs";
import { LuLogOut } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "firebase/auth";
import { auth } from "../../../utils/firebase";
import { removeUser } from "redux/slices/auth";
import { doc, getDoc, runTransaction } from "firebase/firestore";
import { db } from "../../../utils/firebase";
import { useEffect } from "react";
import { PiEyeClosedDuotone } from "react-icons/pi";
// import axios from "axios";
import Loader from "components/Loader";
import { toast } from "react-toastify";

const AccountDetails = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  const [userData, setUserData] = useState({});

  const getUserData = async () => {
    if (user) {
      // Check if userInfo is defined
      const docRef = doc(db, "users", user?._id); // Use userInfo._id instead of userInfo?._id
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUserData(docSnap.data());
      } else {
        // docSnap.data() will be undefined in this case
        // console.log('No such document!');
        // toast.error("Sign In!");
      }
    }
  };

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const toggleInputType = () => {
    setInputType(inputType === "password" ? "tel" : "password");
  };

  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [bvnNumber, setBvnNumber] = useState("");
  const [bvnNumberValid, setBvnNumberValid] = useState(true);
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [inputType, setInputType] = useState("password");

  useEffect(() => {
    setFirstName(userData.username);
    setLastName(userData.userlastname);
    setMobileNumber(userData.phoneNumber);
    setBvnNumber(userData.bvnNumber);
    setAddress(userData.useraddress);
    setState(userData.userstate);
  }, [userData]);

  const handleAccountDetails = async (e) => {
    e.preventDefault();
    setLoading(true);

    // if (bvnNumber === "" || bvnNumber.length !== 11 || bvnNumberValid === null) {
    //   return toast.error("Invalid BVN");
    // }

    if (firstName === "" || lastName === "" || mobileNumber === "" || address === "" || state === "") {
      return toast.error("All fields are required");
    }

    try {
      const userRef = doc(db, "users", user._id);
      await runTransaction(db, async (transaction) => {
        const userDoc = await transaction.get(userRef);
        if (!userDoc.exists()) {
          // console.log('Document does not exist!');
        } else {
          // console.log('Document does exist!');
        }

        // Get the current data
        const userData = userDoc.data() || {};

        // Merge the new data with the existing data
        const updatedData = {
          ...userData,
          username: firstName,
          userlastname: lastName,
          phoneNumber: mobileNumber,
          // bvnNumber: bvnNumber,
          useraddress: address,
          userstate: state,
        };

        // Update the user document with the merged data
        transaction.update(userRef, updatedData);
      });

      toast.success("Account details updated successfully");

      setLoading(false);
      // cnsole.log('Transaction successful');
    } catch (error) {
      setLoading(false);
      // Handle the error appropriately, e.g., display a message to the user
      // console.error('Error updating user data:', error);
    }
  };

  const handleLogout = (e) => {
    e.preventDefault();
    signOut(auth)
      .then(() => {
        dispatch(removeUser());
        setTimeout(() => {
          navigate("/");
        }, 1000);

        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };
  return (
    <div>
      <div className="flex justify-center mx-auto w-full max-w-7xl items-center my-10">
        <div className="md:flex justify-center items-start xs:mx-3 sm:mx-24  gap-20 w-full ">
          <div className="w-full md:w-72 font-rajdhani">
            <Link to="/dashboard">
              <div className="  p-2  rounded my-2 border-[1px] border-gray-200 cursor-pointer">
                <div className="flex gap-2 items-center pl-5">
                  <BsBagDash />
                  <h6 className="font-semibold text-lg">Orders</h6>
                </div>
              </div>
            </Link>
            {/* <Link to="/change-password">
              <div className=" p-2  rounded my-2 border-[1px] border-gray-200 cursor-pointer">
                <div className="flex gap-2 items-center pl-5">
                  <RiShieldKeyholeLine />
                  <h6 className="font-semibold text-lg">Change Password</h6>
                </div>
              </div>
            </Link> */}
            <Link to="/account_details">
              <div className=" p-2  rounded my-2 border-[1px] bg-[#0f8c3bb9] border-gray-200 cursor-pointer">
                <div className="flex gap-2 items-center pl-5">
                  <BiUser className="font-semibold text-lg text-slate-100" />
                  <h6 className="font-semibold text-lg text-slate-100">Account Details</h6>
                </div>
              </div>
            </Link>
            <div onClick={handleLogout} className=" p-2  rounded my-2 border-[1px] border-gray-200 cursor-pointer">
              <div className="flex gap-2 items-center pl-5">
                <LuLogOut />
                <h6 className="font-semibold text-lg">Logout</h6>
              </div>
            </div>
          </div>
          <div className="w-full my-2">
            <div>
              <form>
                <div className="mt-4">
                  <h6 className="font-bold">Account Details</h6>
                </div>
                <div className="w-full">
                  <div className="w-full font-rajdhani">
                    <div className="md:flex flex-row gap-5">
                      <div className="w-full my-2 flex flex-col">
                        <label>First Name*</label>
                        <input
                          onChange={(e) => setFirstName(e.target.value)}
                          className="w-full my-2 h-12 p-4 rounded border border-gray-300  focus:outline-none focus:ring-1 focus:ring-[#0F8C3B] text-lg font-bold"
                          type="text"
                          required
                          value={firstName}
                        ></input>
                      </div>
                      <div className="w-full my-2 flex flex-col">
                        <label>Last Name*</label>
                        <input
                          onChange={(e) => setLastName(e.target.value)}
                          className="w-full my-2 h-12 p-4 rounded border border-gray-300  focus:outline-none focus:ring-1 focus:ring-[#0F8C3B] text-lg font-bold"
                          type="text"
                          required
                          value={lastName}
                        ></input>
                      </div>
                    </div>
                    <div className="w-full my-2 flex flex-col">
                      <label>Mobile number*</label>
                      <input
                        onChange={(e) => setMobileNumber(e.target.value)}
                        className="w-full my-2 h-12 p-4 rounded border border-gray-300  focus:outline-none focus:ring-1 focus:ring-[#0F8C3B] text-lg font-bold"
                        type="tel"
                        required
                        value={mobileNumber}
                      ></input>
                    </div>
                    {/* <div className="w-full my-2 flex flex-col">
                      <label>BVN number*</label>
                      <div className="flex w-full relative">
                        <div className="w-full">
                          <input
                            onChange={(e) => {
                              const isValidBvnNumber = /^[0-9]{11}$/.test(e.target.value);
                              setBvnNumber(e.target.value);
                              setBvnNumberValid(isValidBvnNumber);
                            }}
                            className={`w-full h-12 p-4 border  focus:outline-none focus:ring-1${
                              bvnNumberValid ? " focus:ring-[#0F8C3B]" : " border-gray-300 focus:ring-red-500 "
                            }`}
                            type={inputType}
                            required
                            value={bvnNumber}
                          ></input>
                        </div>
                        <div className="absolute right-6 top-6 cursor-pointer" onClick={toggleInputType}>
                          <span>
                            <PiEyeClosedDuotone />
                          </span>
                        </div>
                      </div>
                    </div> */}
                    <div className="w-full my-2 flex flex-col">
                      <label>Address*</label>
                      <input
                        onChange={(e) => setAddress(e.target.value)}
                        className="w-full my-2 h-12 p-4 rounded border border-gray-300  focus:outline-none focus:ring-1 focus:ring-[#0F8C3B] text-lg font-bold"
                        type="text"
                        required
                        value={address}
                      ></input>
                    </div>
                    <div className="w-full my-2 flex flex-col">
                      <label>State*</label>
                      <input
                        onChange={(e) => setState(e.target.value)}
                        className="w-full my-2 h-12 p-4 rounded border border-gray-300  focus:outline-none focus:ring-1 focus:ring-[#0F8C3B] text-lg font-bold"
                        type="text"
                        required
                        value={state}
                      ></input>
                    </div>
                    <div className="flex gap-4">
                      <button onClick={handleAccountDetails} className="green_btn">
                        {loading ? <Loader /> : "Save Changes"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="flex rounded-md mx-4 my-14 bg-[url('https://nest.apexcode.info/static/assets/imgs/banner/banner-10.png')]">
        <div className="w-[50%] flex flex-col justify-center pl-20 font-rajdhani">
          <div>
            <h2 className="text-5xl font-semibold">Stay home &amp; get your daily</h2>
            <h2 className="text-5xl font-semibold">needs from our shop </h2>
            <p className=" text-lg font-semibold">
              Start You'r Daily Shopping with <span className="text-brand">Agro</span>
            </p>
          </div>
          <div>
            <form method="POST">
              <input
                className="w-72 h-16 rounded-full bg-[url('https://nest.apexcode.info/static/assets/imgs/theme/icons/icon-plane.png')] bg-no-repeat bg-left pl-9 focus:outline-none"
                type="email"
                placeholder="Your emaill address"
                required
              />
              <button
                className="h-16 w-32 -ml-12 rounded-full font-semibold text-white bg-[#0f8c3b] hover:bg-[#0f8c3bb9]"
                type="submit"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>
        <div className="w-[50%]">
          <img
            className="px-5 pt-20"
            src="https://nest.apexcode.info/media/site_logo/imgs/banner-9.png"
            alt="newsletter"
          />
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
