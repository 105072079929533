import React from "react";

const SecondFooter = () => {
  return (
    <footer className="w-full bg-[#071c1f] text-white m-0 p-0 ">
      <div className="md:flex justify-between px-4 py-2 ">
        <div className="text-center font-rajdhani">
          <p>
            Copyright ©<strong>Agro Market Square</strong> All Right Reserved. Built by{" "}
            <a href="https://kreatixtech.com/" className="font-semibold" target="_blank" rel="noreferrer">
              Kreatix Technologies
            </a>
          </p>
        </div>
        <div className="flex justify-center items-center gap-4 md:text-xs text-base font-rajdhani font-bold">
          <ul className=" flex flex-wrap justify-center items-center gap-4 ">
            <li className="cursor-pointer hover:text-[#80b500]">
              <a href="/terms-and-conditions">Terms of Service</a>
            </li>
            <li className="cursor-pointer hover:text-[#80b500]">
              <a href="/terms-and-conditions">CLaim</a>
            </li>
            <li className="cursor-pointer hover:text-[#80b500]">
              <a href="/privacy-and-policy">Privacy Policy</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default SecondFooter;
