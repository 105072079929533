import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import config from "../constants/config";

export const app = initializeApp(config.FB);
const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export { db };

export function setUpRecaptcha(number) {
  const recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", {}, auth);
  recaptchaVerifier.render();
  return signInWithPhoneNumber(auth, number, recaptchaVerifier);
}

// const analytics = getAnalytics(app);

// export const uploadFile = async (filePathAndName, file, setReturnType, setProgressFunc, setUploadTaskInstance) => {
//     const storageRef = ref(storage, filePathAndName);

//     const uploadTask = uploadBytesResumable(storageRef, file);

//     uploadTask.on(
//         'state_changed',
//         (snapshot) => {
//             const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//             setProgressFunc && setProgressFunc(Math.floor(progress));
//         },
//         (error) => {
//             console.log(error.code, error.message);
//             error.code !== 'storage/canceled' && toast.error(error.message + '. Please reupload ' + file.name);
//             setReturnType('error');
//         },
//         () => {
//             getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => setReturnType(downloadURL));
//         }
//     );

//     setUploadTaskInstance && setUploadTaskInstance(uploadTask);

// };
