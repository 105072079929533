import React from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = () => {
    const {pathname} = useLocation();

    useEffect(()=>{
        window.scroll(0,0);
    },[pathname])
  return (
    <div>
      
    </div>
  )
}

export default ScrollToTop
