import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

export const PrivateRoutes = () => {
    const { user } = useSelector((state) => state.auth);
    if (!user) {
        window.open('/login', '_self');
        return;
    }
    return <Outlet />;
};
