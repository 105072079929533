import React from 'react'
import { FaRegCalendarAlt } from 'react-icons/fa'

const RecentNewsComponent = ({details}) => {
  return (
    <div>
        <div className='flex gap-2 py-4 font-rajdhani '>
            <div>
                <img className='w-20' src={details.postImage} alt='imagenews'/>
            </div>
            <div>
                <h6 className='text-sm font-bold'>{details.postTitle && details.postTitle.slice(0,20)}...</h6>
                <div className='flex flex-row gap-2'>
                    <FaRegCalendarAlt className='text-[#0F8C3B]'/>
                    <span className='font-rajdhani text-xs font-bold text-[#0F8C3B]'>{details.postTime}</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default RecentNewsComponent