import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.user = action.payload;
    },
    removeUser: (state) => {
      state.user = null;
    },

  },
});

export const {addUser, removeUser} = auth.actions;
export default auth.reducer;
